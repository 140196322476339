import { request, success, failure, MAIN_DASHBOARD, ANC_DASHBOARD } from "./utilities";
import axios from "axios";
import {
  getStatsApi,
  getHistoryGraphApi,
  getCaseProfileTableApi,
  getSmsNudgesListApi,
  exportHistoryGraphDataApi,
  getAllCountApi,
  getNotifyVisitorsListApi,
  exportNotifyUserDataApi,
  sendNotifiedUserManualMessageApi,
  getIVRListingApi,
  exportIVRDataApi,
  getGenericDashboardStatsApi,
  getExportGenericDashboardStatsApi,
  getBatchListApi,
  addBatchApi,
  deleteBatchApi,
  getGlobalPlusDashboardApi,
  getTargetListApi,
  addTargetApi,
  deleteTargetApi,
  addBulkBatchApi,
  getNutritionSummaryApi,
  getExportClinicApi,
  getGenericChildDetailsApi,
} from "../api";

import { downloadBase64File } from "../../app/constant";
import { baseURL } from "../config";

export function getStateAction(filters, urlState, moveToNext) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_STATS_REQUEST));
    getStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_STATS_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_STATS_FAILURE, error.message));
      }
    );
  };
}

export function getGraphDataAction(filters, moveToNext, urlState) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_GRAPH_DATA_REQUEST));
    getGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_GRAPH_DATA_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext()
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_GRAPH_DATA_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_GRAPH_DATA_FAILURE, error.message));
      }
    );
  };
}
export function exportClinicAction(filters, moveToNext, urlState) {
  let originalString = urlState;
  let fileName = originalString.replace("Export", "");
  let randomNumber = Math.floor(Math.random() * 100);
  fileName += randomNumber;
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_REQUEST));
    getExportClinicApi(filters, urlState).then(
      (response) => {
        if (response.data !== undefined) {
          dispatch(
            success(
              ANC_DASHBOARD.EXPORT_ANC_STATS_EXCEL_SUCCESS,
              response.data
            )
          );
          downloadBase64File(response.data, fileName + ".xlsx");
          if (moveToNext) {
            moveToNext(response)
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_FAILURE, error.message));
      }
    );
  };
}
export function exportReportStateAction(filters, moveToNext, urlState) {
  let originalString = urlState;
  let fileName = originalString.replace("Export", "");
  let randomNumber = Math.floor(Math.random() * 100);
  fileName += randomNumber;
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_REQUEST));
    getExportGenericDashboardStatsApi(filters, urlState).then(
      (response) => {
        if (response.data !== undefined) {
          dispatch(
            success(
              ANC_DASHBOARD.EXPORT_ANC_STATS_EXCEL_SUCCESS,
              response.data
            )
          );
          downloadBase64File(response.data, fileName + ".xlsx");
          if (moveToNext) {
            moveToNext(response)
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_VISIT_SUMMARY_STATS_FAILURE, error.message));
      }
    );
  };
}
export function getHistoryGraph(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_HISTORY_GRAPH_REQUEST));
    getHistoryGraphApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_HISTORY_GRAPH_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_HISTORY_GRAPH_FAILURE,
              response.data.message
            )
          );
          // Notificiation(response.data.message, "error")
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_HISTORY_GRAPH_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getCaseProfileAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_REQUEST));
    getCaseProfileTableApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_FAILURE, error.message)
        );
      }
    );
  };
}
export function getBatchListingAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_BATCH_LIST_REQUEST));
    getBatchListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_BATCH_LIST_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_BATCH_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.GET_BATCH_LIST_FAILURE, error.message)
        );
      }
    );
  };
}
export function getTargetListingAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_TARGET_LIST_REQUEST));
    getTargetListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_TARGET_LIST_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_TARGET_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.GET_TARGET_LIST_FAILURE, error.message)
        );
      }
    );
  };
}
export function addBatchgAction(data, onSuccess) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.ADD_BATCH_REQUEST));
    addBatchApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.ADD_BATCH_SUCCESS,
              response.data.data
            )
          );
          if (onSuccess) {
            onSuccess()
          }
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.ADD_BATCH_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.ADD_BATCH_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function addBulkStockBatchAction(filters, onSuccess) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.ADD_BATCH_BULK_REQUEST));
    addBulkBatchApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.ADD_BATCH_BULK_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
          if (onSuccess) {
            onSuccess(response.data.message)
          }
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.ADD_BATCH_BULK_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.ADD_BATCH_BULK_FAILURE, error.message)
        );
      }
    );
  };
}



export function addTargetAction(data, onSuccess) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.ADD_TARGET_REQUEST));
    addTargetApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.ADD_TARGET_SUCCESS,
              response.data.data
            )
          );
          if (onSuccess) {
            onSuccess()
          }
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.ADD_TARGET_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.ADD_TARGET_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function deleteBatchgAction(filters, modalClose) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.DELETE_BATCH_REQUEST));
    deleteBatchApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.DELETE_BATCH_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )

          );
          if (modalClose) {
            modalClose()
          }
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.DELETE_BATCH_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.DELETE_BATCH_FAILURE, error.message)
        );
      }
    );
  };
}
export function deleteTargetAction(filters, modalClose) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.DELETE_TARGET_REQUEST));
    deleteTargetApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.DELETE_TARGET_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )

          );
          if (modalClose) {
            modalClose()
          }
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.DELETE_TARGET_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.DELETE_TARGET_FAILURE, error.message)
        );
      }
    );
  };
}
export function updateBatchgAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.UPDATE_BATCH_REQUEST));
    getBatchListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.UPDATE_BATCH_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.UPDATE_BATCH_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.UPDATE_BATCH_FAILURE, error.message)
        );
      }
    );
  };
}

export function getAllCountAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_ALL_COUNT_REQUEST));
    getAllCountApi().then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_ALL_COUNT_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_ALL_COUNT_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_ALL_COUNT_FAILURE, error.message));
      }
    );
  };
}

export function getIVRListingList(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_IVR_LIST_REQUEST));
    getIVRListingApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_IVR_LIST_SUCCESS, response.data.data, {
              count: response.data.totalRecords,
            })
          );
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_IVR_LIST_FAILURE, response.data.message)
          );
          // Notificiation(response.data.message, "error")
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_IVR_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function getSmsNudgesList(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_REQUEST));
    getSmsNudgesListApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_FAILURE,
              response.data.message
            )
          );
          // Notificiation(response.data.message, "error")
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getNotifyVisitorsList(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_REQUEST));
    getNotifyVisitorsListApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function exportHistoryGraphData(
  filterData,
  fileName,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_REQUEST));
    exportHistoryGraphDataApi(filterData)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName+ ".xlsx");
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_FAILURE,
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function exportNotifyUserData(filterData, fileName, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_REQUEST));
    exportNotifyUserDataApi(filterData)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_FAILURE,
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}


export function exportIVRData(filterData, fileName, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(MAIN_DASHBOARD.EXPORT_IVR_DATA_REQUEST));
    exportIVRDataApi(filterData)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                MAIN_DASHBOARD.EXPORT_IVR_DATA_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              MAIN_DASHBOARD.EXPORT_IVR_DATA_FAILURE,
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
export function sendNotifiedUserManualMessageActions(data, moveToNext) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_REQUEST));
    sendNotifiedUserManualMessageApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_SUCCESS,
              response.data
            )
          );
          moveToNext();
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getGlobalPlusDashboardDataAction(baseUrl, filters, moveToNext, urlState, key) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_SECTION_GRAPH_LISTING_REQUEST, key));
    getGlobalPlusDashboardApi(baseUrl, filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_SECTION_GRAPH_LISTING_SUCCESS, response.data.data, { count: response.data.totalRecords }, key)
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_SECTION_GRAPH_LISTING_FAILURE, response.data.message, key)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_SECTION_GRAPH_LISTING_FAILURE, error.message, key));
      }
    );
  };
}
export function getGenericSectionDataAction(baseUrl, filters, moveToNext, urlState, key) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_SECTION_DATA_REQUEST, key));
    getGlobalPlusDashboardApi(baseUrl, filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_SECTION_DATA_SUCCESS, response.data.data, { count: response.data.totalRecords }, key)
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_SECTION_DATA_FAILURE, response.data.message, key)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_SECTION_DATA_FAILURE, error.message, key));
      }
    );
  };
}
export function getGenericChildDetailsAction(baseUrl, filters, moveToNext, urlState, key) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_SECTION_DATA_REQUEST, key));
    getGenericChildDetailsApi(baseUrl, filters, urlState).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_SECTION_DATA_SUCCESS, response.data.data, { count: response.data.totalRecords }, key)
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_SECTION_DATA_FAILURE, response.data.message, key)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_SECTION_DATA_FAILURE, error.message, key));
      }
    );
  };
}
export function NutritionSummaryListAction(filters, moveToNext) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_NUTRITION_SUMMMARY_REQUEST));
    getNutritionSummaryApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_NUTRITION_SUMMMARY_SUCCESS, response.data.data, { count: response.data.totalRecords })
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_NUTRITION_SUMMMARY_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_NUTRITION_SUMMMARY_FAILURE, error.message));
      }
    );
  };
}
const buildQueryString = (filters) => {
  const query = Object.entries(filters)
      .filter(([_, value]) => value !== null && value !== "") // Remove null/empty values
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");
  return query;
};
export const NutritionSummaryAction = (filters, graphType, onSuccess) => async (dispatch) => {
  try {
      const queryString = buildQueryString(filters); // Build query string

      const response = await axios.get(
          `${baseURL}/RegisterDashboard/${graphType}?${queryString}`
      );

      if (onSuccess) onSuccess(response.data);
      return response.data;
  } catch (error) {
      console.error(`Error fetching ${graphType} data:`, error);
  }
};
