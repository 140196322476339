import { format } from "date-fns";
import { useSelector } from "react-redux";

/**
 * Function to Sum in array
 *
 * @param {*} array
 * @param {*} key
 * @return {Number} //Total of key value in Array
 */

export const arraySum = (array, key) => {
  return array.reduce((acc, item) => acc + (+item[key] || 0), 0);
};

/**
 * Function to get current month in full Name
 *
 * @return {*}
 */
export const getCurrentMonthName = () => {
  let dateNow = new Date();
  return format(dateNow, "MMMM");
};

/**
 * Function to get current month in (1, 2, 3 form)
 *
 * @return {Number} month in number form
 */
export const getCurrentMonthInNumber = () => {
  let dateNow = new Date();
  return +format(dateNow, "M");
};

/**
 * Function to get current year in (2022, 2023 form)
 *
 * @return {Number} Year in number form
 */
export const getCurrentYear = () => {
  let dateNow = new Date();
  return +format(dateNow, "yyyy");
};

/**
 * Function to get current year in (2022, 2023 form)
 *
 * @return {Number} Year in number form
 */
export const getCurrentDay = () => {
  let dateNow = new Date();
  return +format(dateNow, "d");
};

/**
 * OnChange function for handling Filters States
 *
 * @param {*} value
 * @param {*} name
 * @param {*} { state, setState }
 */

export const handleFilters = (value, name, { state, setState }) => {

  let filters = { ...state.filters };
  let values = { ...state.values };

  if (value) {
    filters = { ...filters, [name]: value.value !== undefined ? value?.value : value };
    values = { ...values, [name]: value };
  } else {
    //If Click clear Btn we have no Value (So Remove Key from Filter State)
    let { [name]: removeFilter, ...omittedFilters } = filters;
    let { [name]: removeValue, ...omittedValues } = values;
    filters = omittedFilters;
    values = omittedValues;
  }
  setState({ ...state, filters: filters, values: values });
};
export const handleMultiSelectFilters = (value, name, { state, setState }) => {
  let filters = { ...state.filters };
  let values = { ...state.values };

  if (value) {
    filters = { ...filters, [name]: value !== undefined ? `${value?.map((clinic) => clinic.value).join(',')}` : null };
    values = { ...values, [name]: value };
  } else {
    //If Click clear Btn we have no Value (So Remove Key from Filter State)
    let { [name]: removeFilter, ...omittedFilters } = filters;
    let { [name]: removeValue, ...omittedValues } = values;
    filters = omittedFilters;
    values = omittedValues;
  }
  setState({ ...state, filters: filters, values: values });
};

/**
 * Function to Download File from Base64 data
 *
 * @param  {*} data //data in Base64
 * @param  {*} fileName //file name to download
 */
export const downloadBase64File = (data, fileName) => {
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: "application/octet-stream",
      // type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * Function to get Thumbnial from Video
 *
 * @param  {*} videoUrl //Base64
 */
export const getThumbnailForVideo = async (videoUrl) => {
  const video = document.createElement("video");
  const canvas = document.createElement("canvas");
  video.style.display = "none";
  canvas.style.display = "none";

  // Trigger video load
  await new Promise((resolve, reject) => {
    video.addEventListener("loadedmetadata", () => {
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // Seek the video to 25%
      video.currentTime = video.duration * 0.25;
    });
    video.addEventListener("seeked", () => resolve());
    video.src = videoUrl;
  });

  // Draw the thumbnailz
  canvas
    .getContext("2d")
    .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  const imageUrl = canvas.toDataURL("image/png");
  //custom add for Duration
  const duration = video.duration;
  return [imageUrl, duration];
};

/**
 * Function Based Sleep for delay
 *
 * @param  {} ms
 */
export const sleep = (ms = 1000) => new Promise((r) => setTimeout(r, ms));

/**
 * Function to Remove Falsy key from Object
 *
 * @param  {Object} object
 */
export const removeFalsyElement = (object) => {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      newObject[key] = object[key];
    }
  });
  return newObject;
};

export const executeScroll = (element) =>
  element?.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });

/**
 * Generic Function for Chart Theme
 *
 * @param {String} id
 * @param {Array} categories
 * @return {*}
 */
export const chartOptionFunction = (id, sectionGraphData) => {
  const max = Array.isArray(sectionGraphData?.dataArray) ? Math.max(...sectionGraphData?.dataArray) : 20;

  return {

    chart: {
      id: id,
      width: "100%",
      height: "500px",
      toolbar: {
        show: false,
      },
    },
    colors: ["#FFA27A"],
    // responsive: [
    //   {
    //     breakpoint: 768,
    //     options: {
    //       chart: {
    //         width: "100%",
    //         height: "300px",
    //       },
    //     },
    //   },
    // ],
    stroke: {
      show: true,
      // curve: "smooth",
      lineCap: "butt",
      colors: "#FFA27A",
      width: 2,
      dashArray: 0,
    },
    markers: {
      size: 5,
      colors: "#FFA27A",
      strokeColors: "#fff",
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    grid: {
      show: true,
      borderColor: "#F4F4F4",
      strokeDashArray: 0,
      position: "back",
      // xaxis: {
      //   lines: {
      //     show: true,
      //   },
      // },
      // yaxis: {
      //   lines: {
      //     show: true,
      //   },
      // },
    },
    yaxis: {
      title: {
        text: ''
      },
      min: 0,
      max: (sectionGraphData && sectionGraphData?.maxValue) || max,
    },
    xaxis: {
      categories: sectionGraphData?.categories || [],
      //  categories: [],

    },

    annotations: {
      yaxis: [
        {
          y: (sectionGraphData && sectionGraphData?.target) || 0,
          borderColor: `${sectionGraphData?.target ? "#00E396" : ""} `,
          label: {
            borderColor: `${sectionGraphData?.target ? "#00E396" : ""} `,
            style: {
              color: `${sectionGraphData?.target ? "#fff" : ""} `,
              background: `${sectionGraphData?.target ? "#00E396" : ""} `,
            },
            text: `${sectionGraphData?.target ? `Target: ${sectionGraphData && sectionGraphData?.target}` : ""} ` // label for the horizontal line
          },
        },
      ],
    },
  };
};

export const chartOptionFunctionTest = (id, categories) => {
  return {
    chart: {
      id: id,
      width: "100%",
      height: "500px",
      toolbar: {
        show: false,
      },
    },
    colors: ["#FFA27A"],
    // responsive: [
    //   {
    //     breakpoint: 768,
    //     options: {
    //       chart: {
    //         width: "100%",
    //         height: "300px",
    //       },
    //     },
    //   },
    // ],
    shared: false,
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        function getPercentage(num, total) {
          let result = (num / total) * 100;
          result = result || 0;

          return `${parseFloat(result.toFixed(2))}%`;
        }

        let seriesName = w.config.series[0].name;
        let seriesData = w.config.series[0].data[dataPointIndex];

        const htmlString = `
        <div class="card p-3">
          <p class="font-size-lg font-weight-bolder mb-2">${
          // series[seriesIndex][dataPointIndex]
          seriesData.x
          }</p>

          <div class="d-flex">
            <p class="font-size-sm font-weight-bolder m-0">${seriesName}:</p>
            <span class="font-size-sm ml-3">${`${seriesData.y || 0}`}</span>
          </div>

          <div class="d-flex">
            <p class="font-size-sm font-weight-bolder m-0">Male (%):</p>
            <span class="font-size-sm ml-3">${getPercentage(
            seriesData.totalMale,
            seriesData.y
          )}  (${`${seriesData.totalMale || 0})`}</span>
          </div>
          <div class="d-flex">
            <p class="font-size-sm font-weight-bolder m-0">Female (%):</p>
            <span class="font-size-sm ml-3">${getPercentage(
            seriesData.totalFemale,
            series[seriesIndex][dataPointIndex]
          )}  (${`${seriesData.totalFemale || 0})`}</span>
          </div>
        </div>`;

        return htmlString;
      },
    },
    stroke: {
      show: true,
      // curve: "smooth",
      lineCap: "butt",
      colors: "#FFA27A",
      width: 2,
      dashArray: 0,
    },
    markers: {
      size: 5,
      colors: "#FFA27A",
      strokeColors: "#fff",
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    grid: {
      show: true,
      borderColor: "#F4F4F4",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: categories || [],
    },
    // annotations: {
    //   yaxis: [
    //     {
    //       y: 10, // the value where you want the line
    //       borderColor: "#00E396", // color of the line
    //       label: {
    //         borderColor: "#00E396",
    //         style: {
    //           color: "#fff",
    //           background: "#00E396",
    //         },
    //         text: `Target: ${10}`, // label for the horizontal line
    //       },
    //     },
    //   ],
    // },
  };
};

// It takes param as seconds and returns hrs, mins, secs
export function convertMinsIntoCorrectTimeFormat(secs) {
  var hrs = Math.floor(secs / 3600);
  var min = Math.floor((secs % 3600) / 60);
  var sec = Math.floor((secs % 3600) % 60);
  if (hrs.toString().length < 2) {
    hrs = `0${hrs}`;
  }
  if (min.toString().length < 2) {
    min = `0${min}`;
  }
  if (sec.toString().length < 2) {
    sec = `0${sec}`;
  }
  return `${hrs}h ${min}m ${sec}s`;
}
export const isSectionPermissionAllowed = (policesData, value) => {
  return (
    policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.filter(
      (items) => items?.value.startsWith(value)
    ).length > 0
  );
};
export const getSummaryPaths = (dashboardType) => {
  const summaryPaths = {
    OpdOverFive: {
      VisitSummaryPath: 'VisitsSummary',
      ModernBirthSpacingSummaryPath: 'ModernBirthSpacingSummary',
      AnemiaandTreatmentSummaryPath: 'AnemiaandTreatmentSummary',
      DiarrhoeaSummaryPath: 'DiarrhoeaSummary',
      MalariaSummaryPath: 'MalariaSummary',
      PlasmodiumSpeciesSummaryPath: 'PlasmodiumSpeciesSummary',
      RapeAndGBVCasesSummaryPath: 'RapeAndGBVCasesSummary',
      UnderFiveMalariaSummaryPath: "PrimaryHealthUnitPlasmodiumSpecies",
      OutcomeSummaryPath: 'OutcomeSummary'
    },
    primaryHealthUnit: {
      VisitSummaryPath: 'PrimaryHealthUnitVisitsSummary',
      ModernBirthSpacingSummaryPath: 'PrimaryHealthUnitNutritionalSummary',
      AnemiaandTreatmentSummaryPath: 'PrimaryHealthUnitNutritionReferrals',
      DiarrhoeaSummaryPath: 'PrimaryHealthUnitProphylaxis',
      MalariaSummaryPath: 'PrimaryHealthUnitRespiratoryInfections',
      PlasmodiumSpeciesSummaryPath: 'PrimaryHealthUnitDiarrheaSummary',
      RapeAndGBVCasesSummaryPath: 'PrimaryHealthUnitMalariaSummary',
      UnderFiveMalariaSummaryPath: "PrimaryHealthUnitPlasmodiumSpecies",
      OutcomeSummaryPath: 'PrimaryHealthUnitOutcome'
    },
    InPatientRegister: {
      VisitSummaryPath: 'NumberOfAdmissions',
      ModernBirthSpacingSummaryPath: 'TotalPatientDays',
      AnemiaandTreatmentSummaryPath: 'DischargeSummary',
      DiarrhoeaSummaryPath: 'DischargeStatus',
      MalariaSummaryPath: 'DeathSummary',
      PlasmodiumSpeciesSummaryPath: `PlasmodiumSpecies`,
      RapeAndGBVCasesSummaryPath: `DiarrheaSummary`,
      UnderFiveMalariaSummaryPath: "PrimaryHealthUnitPlasmodiumSpecies",
      OutcomeSummaryPath: `Outcome`
    },
    OpdUnderFive: {
      VisitSummaryPath: 'UnderFiveVisitsSummary',
      ModernBirthSpacingSummaryPath: 'UnderFiveNutritionalSummary',
      AnemiaandTreatmentSummaryPath: 'UnderFiveNutritionReferrals',
      DiarrhoeaSummaryPath: 'UnderFiveProphylaxis',
      MalariaSummaryPath: 'UnderFiveRespiratoryInfections',
      PlasmodiumSpeciesSummaryPath: 'UnderFivePlasmodiumSpecies',
      RapeAndGBVCasesSummaryPath: 'UnderFiveDiarrheaSummary',
      UnderFiveMalariaSummaryPath: "UnderFiveMalariaSummary",
      OutcomeSummaryPath: `UnderFiveOutcome`
    },
    postNatalCare: {
      VisitSummaryPath: 'PNCVisitSummary',
      ModernBirthSpacingSummaryPath: 'PNCExamMother',
      AnemiaandTreatmentSummaryPath: 'PNCBirthSpacing',
      DiarrhoeaSummaryPath: 'PNCAnemia',
      MalariaSummaryPath: 'PNCPMTCTSummary',
      PlasmodiumSpeciesSummaryPath: 'PNCStateOfBaby',
      RapeAndGBVCasesSummaryPath: 'PNCHealthPromotion',
      UnderFiveMalariaSummaryPath: "PrimaryHealthUnitPlasmodiumSpecies",
      OutcomeSummaryPath: 'PrimaryHealthUnitOutcome'
    },
    labourDeliveryDashboard: {
      VisitSummaryPath: 'LDMGestationAtDelivery',
      ModernBirthSpacingSummaryPath: 'LDMLocationAndTypeOfDelivery',
      AnemiaandTreatmentSummaryPath: 'LDMMaternalComplications',
      DiarrhoeaSummaryPath: 'LDMPartograph',
      MalariaSummaryPath: 'LDMAnemiaAndEarlyBreastfeeding',
      PlasmodiumSpeciesSummaryPath: 'LDMAPGARScore',
      RapeAndGBVCasesSummaryPath: 'LDMBabySex',
      UnderFiveMalariaSummaryPath: "LDMBirthWeight",
      OutcomeSummaryPath: 'LDMInfantOutcome',
      LDMImmunization: 'LDMImmunization',
      LDMMotherAtDischarge: 'LDMMotherAtDischarge',
      LDMBabyAtDischarge: 'LDMBabyAtDischarge',
      LDMPMTCTSummary: 'LDMPMTCTSummary',
      LDMARTProphylaxis: 'LDMARTProphylaxis',
      LDMReferralSummary: 'LDMReferralSummary',
    },
    washRegister: {
      VisitSummaryPath: 'WashMonthlyReporting',
    },
    ProtectionMonthlyReporting: {
      VisitSummaryPath: 'ProtectionMonthlyReporting',
    },
    EmergencyPreparednessMonthlyReporting: {
      VisitSummaryPath: 'EmergencyPreparednessMonthlyReporting',
    },
    otpDashboard: {
      VisitSummaryPath: 'OTPScreenings',
      ModernBirthSpacingSummaryPath: 'TreatmentOfUncomplicatedSAM',
      AnemiaandTreatmentSummaryPath: 'Counselling',
    },
    scDashboard: {
      VisitSummaryPath: 'TreatmentOfComplicatedSAM',
      AnemiaandTreatmentSummaryPath: 'ScCounselling',
    },
    supplyManagementDashboard: {
      VisitSummaryPath: 'SupplyManagement',
    },
    OTPvsSC: {
      VisitSummaryPath: 'OTPvsSCDischargeSummary',
      ModernBirthSpacingSummaryPath: 'OTPvsSCAdmissions',
    },
  };
  return summaryPaths[dashboardType];
}
// export function getValueLabel(value, list, valueSelector, labelSelector) {
//   // eslint-disable-next-line
//   let label = list.find((item) => item[valueSelector] == value)[labelSelector];
//   return label;
// }
export function getValueLabel(value, list, valueSelector, labelSelector) {
  const item = list.find((item) => item[valueSelector] == value);
  return item ? item[labelSelector] : '';
}


export function returnDateInCorrectFormat(param) {
  if (param == null)
    return "N/A";
  let date = param?.split(".")[0] + ".000Z";
  return format(new Date(date), "MMM d, yyyy - hh:mm:ss a");
}

